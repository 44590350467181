import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbActiveModal, NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './dialog-rss.component.html',
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers
})
export class DialogRSSComponent implements OnInit {
  @ViewChild('carousel') carousel!: NgbCarousel;
  @Input('id') id!: string;
  @Input('title') title!: string;
  @Input('content') content!: any;
  @Output('opened') opened = new EventEmitter();
  splitcontent: SafeHtml[] = [];
  slide: number = 1;

  constructor(private activeModal: NgbActiveModal, config: NgbCarouselConfig, private sanitizer: DomSanitizer) {
    config.interval = 0;
    config.keyboard = false;
    config.pauseOnHover = false;
    config.showNavigationArrows = this.splitcontent.length > 1;
  }

  ngOnInit(): void {
    // Must not be empty. It's approved by a comment.
  }

  ngAfterViewInit() {
    this.content = this.content.replace(/\n/g, "");
    this.content.split('<hr size="1">').forEach((s: any) => {
      s = this.sanitizer.bypassSecurityTrustHtml(s);
      return this.splitcontent.push(s);
    });
  }

  close() {
    this.activeModal.close();
    this.opened.emit(this.id);
  }
  
  nextStep() {
    if(this.slide < this.splitcontent.length) this.carousel.next();
    this.slide += 1;
  }
  
  start() {
    this.activeModal.close();
    this.opened.emit(this.id);
  }
}
