<div class="modal-body m-0 m-lg-3">
    <div class="mb-4">
        <h3>{{title}}</h3>
    </div>
    <div class="row row-cols-12 default-font-size pb-2">
        <ngb-carousel #carousel class="rss-dialog">
            <ng-template ngbSlide *ngFor='let item of splitcontent; let i = index'>
                <div class="picsum-img-wrapper" class="rss-dialog-inner overflow-y-auto">
                    <div class="renderedData" [innerHTML]="item"></div>
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
    <div class="d-table w-100">
        <span class="float-start"><button class="btn btn-sm btn-outline-primary" type="button" (click)="close()"><span translate>Button.Close</span></button></span>
        <span class="float-end">
            <button *ngIf="splitcontent.length > 1 && slide < splitcontent.length" class="btn btn-sm btn-success" type="button" (click)="nextStep()"><span translate>Button.Next</span></button>
            <button *ngIf="splitcontent.length === 1 || slide === splitcontent.length" class="btn btn-sm btn-success" type="button" (click)="start()"><span translate>Button.StartV2</span></button>
        </span>
    </div>
</div>
