<div class="vh-100 container d-flex align-items-center">
    <div class="col-12 container-fuid mx-auto bg-white p-0 shadow default-boxrounded overlay-container">
        <div class="position-relative h-100">
            <div class="w-100 p-4 m-2">
                <div class="mb-4">
                    <h3 translate>Login.TimeSynchronisationError</h3>
                </div>
                <p class="mt-3 lh-base">
                    <span translate="Login.TimeSynchronisationErrorInformation"></span><br /><br />
                    <span translate="Login.CloseTab"></span>
                </p>
            </div>
        </div>
    </div>
</div>