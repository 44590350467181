import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DocumentActor } from 'src/app/models/actors';
import { DocumentModel, PageSize, PreloadedPages } from 'src/app/models/document.model';
import { SignatureField } from 'src/app/models/signaturefield';
import { lastValueFrom } from 'rxjs';
import { EditDocumentModalComponent } from '../adddocument/edit-document-modal/edit-document-modal.component';
import { PageService } from 'src/app/services/pages.service';
import { BuilderRequest } from 'src/app/models/requests';
import { LoggingService } from 'src/app/services/logging.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-pdf-preparation-dialog',
  templateUrl: './pdf-preparation-dialog.component.html'
})
export class PdfPreparationDialog implements OnInit {
  // initial size of the signature field
  starting_Width_SignatureFields = 186; // ratio, default V1 width: 177, old v2 width: 87
  starting_Height_SignatureFields = 62; // ratio, default V1 height: 125, old v2 height: 56
  starting_X_Position: number = 40;
  starting_Y_Position: number = 30;
  TOP_MARGIN: number = 28; // based on margin-top of modal window+parent element

  @Output() signatureFieldChanged = new EventEmitter();
  @Output() zoomLevelChanged = new EventEmitter();
  @Output() imageSize = new EventEmitter();
  @Output() preloadedPages = new EventEmitter();
  @Output() forceReloadPage = new EventEmitter();
  @Output() getRequest = new EventEmitter();
  @Output() loadThumbnail = new EventEmitter();
  @Output() forceLoadThumbnail = new EventEmitter();
  @Output() updateDocument = new EventEmitter();
  translations = <any>{};
  request!: BuilderRequest;
  requestId: any;
  imageWidth: number = 150;
  signaturefields: SignatureField[] = [];
  actor!: DocumentActor | null;
  pageId: number = 1;
  //todo: replacy by: Math.abs(Number.parseInt(document.getElementById("panzoomList")!.style.marginTop.replace("px","")));

  pageElement!: HTMLElement;
  topPixelsCurrentPage: number = 0;
  newZoomlevel: number = 1;
  topPixelsPage: number = 0;
  topPixelsCurrentContainer: number = 0;
  leftPixelsCurrentContainer: number = 0;
  pagecontainerOffsetTop: number = 0;
  pagecontainerOffsetLeft: number = 0;
  scrollHeight!: any;
  isDraggable: boolean = false;
  pageSizes: PageSize[] = [];
  initialPageY: number = 0;
  place: boolean = true;
  displayfields: boolean = true;
  showEditDocument: boolean = false;
  setMoreSignaturefields: boolean = false;
  zoomLevels: number[] = [550, 825, 1100];
  private zoomClasses: string[] = ["pkiSm", "pkiMd", "pkiLg"]
  currentZoomLevel: number = 1;
  currentPageByZooming: number | null = null;
  resizableSignatureWidth!: number;

  private _document!: DocumentModel;
  public set document(value: DocumentModel) {
    this._document = value;
    this.pageSizes = JSON.parse(this._document.pageSizes);
  }
  public get document() {
    return this._document;
  }

  constructor(private activeModal: NgbActiveModal, private modalService: NgbModal, private pageService: PageService, private loggingService: LoggingService, private sanitizer: DomSanitizer) {
    //Constructor cannot be empty.
  }

  async ngOnInit(): Promise<void> {
    this.scrollHeight = document.querySelector(".addSignatureFieldModal");
    this.scrollHeight.addEventListener('scroll', (event: any) => { this.onScroll(); });

    await this.loadPageData();
  }

  public async loadPageData() {
    if (this.actor && this.actor.sigFieldPage <= 0) this.actor.sigFieldPage = 1;
    this._document.preloadedPages ??= [];
    if (this.actor && this._document!.preloadedPages.findIndex((s: PreloadedPages) => s.PageId === this.actor!.sigFieldPage) === -1) {
      for (let i = this.actor.sigFieldPage - 3; i <= this.actor.sigFieldPage + 3; i++) {
        if (i <= 0) continue;
        await this.loadPage(this._document, i);
      }
    } else if (this.actor === null || this.actor === undefined) {
      for (let i = 1; i <= 5; i++) {
        await this.loadPage(this._document, i);
      }
    }

    if (this.actor !== null && this.actor !== undefined) this.actor.fieldName = this.determineUniqueFieldName(this.actor?.order + 1)

    setTimeout(() => {
      this.setSignatureFields();
      if (this.actor !== null && this.actor !== undefined) this.scrollToSignaturefield(this.actor!);
    }, 100);
  }

  private setSignatureFields() {
    this.request.actors.filter((act: DocumentActor) => (act.action === "Sign" && act.sigFieldH != 0 && act.sigFieldW != 0) || act.id === this.actor?.id).forEach((actor: DocumentActor) => {
      if (actor.documentId === this.document.id && actor.action === 'Sign' && !actor.hasSigned) {
        if (actor.sigFieldPage === null || actor.sigFieldPage === undefined || actor.sigFieldPage < 1) actor.sigFieldPage = 1;
        this.setSignatureFieldLargeViewport(actor);
      }
    });
  }

  private setSignatureFieldLargeViewport(actor: DocumentActor) {
    this.pageElement = <HTMLElement>document.getElementById(actor.sigFieldPage + "_indicator");
    let pageWidth = this.pageElement.offsetWidth;
    let pageHeight = this.pageElement.offsetHeight;

    this.starting_Width_SignatureFields = pageWidth * 0.20;
    this.starting_Height_SignatureFields = this.starting_Width_SignatureFields / 2.5;
    let starting_X_Position_in_perct = this.starting_Width_SignatureFields / pageWidth * 100;
    this.starting_X_Position = 50 - (starting_X_Position_in_perct / 2);

    let sigfieldX: number = ((pageWidth != null || pageWidth != undefined || actor.sigFieldX != null || actor.sigFieldX != undefined)) ? actor.sigFieldX : this.starting_X_Position;
    let sigfieldY: number = ((pageHeight != null || pageHeight != undefined || actor.sigFieldY != null || actor.sigFieldY != undefined)) ? actor.sigFieldY : this.starting_Y_Position;
    let pagePositionX: number = (pageWidth / 100 * sigfieldX);
    let pagePositionY: number = (pageHeight / 100 * sigfieldY);
    let signfield: SignatureField = new SignatureField;

    if (actor.fullName === undefined || actor.fullName === null) actor.fullName = actor.firstname + " " + (actor.prefix != null ? actor.prefix + " " : "") + actor.lastname;
    let matches: any = actor.fullName.match(/\b(\w)/g);

    let top: number = (this.pageElement.offsetTop + this.pageElement.offsetHeight) + (this.pageElement.offsetParent!.getBoundingClientRect().top);
    if (actor.sigFieldPage === 1) {
      top = this.pageElement.getBoundingClientRect().top;
    }

    signfield.acronym = matches.join('');
    signfield.fullname = actor.fullName;
    signfield.actorId = actor.id;
    signfield.page = (actor.sigFieldPage != null || actor.sigFieldPage < 1) ? actor.sigFieldPage : 1;
    signfield.dragposition = { x: signfield.x, y: signfield.y };
    signfield.name = this.determineUniqueFieldName(actor.order + 1)
    signfield.height = ((pageHeight != null || pageHeight != undefined || actor.sigFieldH != null || actor.sigFieldH != undefined) && pageHeight / 100 * actor.sigFieldH > 0) ? pageHeight / 100 * actor.sigFieldH : this.starting_Height_SignatureFields;
    signfield.width = ((pageWidth != null || pageWidth != undefined || actor.sigFieldW != null || actor.sigFieldW != undefined) && pageWidth / 100 * actor.sigFieldW > 0) ? pageWidth / 100 * actor.sigFieldW : this.starting_Width_SignatureFields;
    signfield.tempx = sigfieldX;
    signfield.tempy = sigfieldY;
    signfield.x = pagePositionX;
    signfield.y = this.pageElement.offsetTop + pagePositionY;
    signfield.readonly = !(actor.id === this.actor?.id);

    if (this.document.documentStatus != "New" && this.document.documentStatus != "Active") signfield.readonly = true;

    if (!signfield.readonly) { // separate calculation for readonly and non-readonly fields as they are displayed in separate layers
      signfield.y = pagePositionY + top;
      this.resizableSignatureWidth = signfield.width;
    }
    this.signaturefields.push(signfield);
  }

  async scrollToPage(pageId: number) {
    let e = <HTMLElement>document.getElementById(pageId + "_indicator");
    e.scrollIntoView({ block: 'center', behavior: "auto", inline: "nearest" });
    this.pageId = pageId;
    for (let i = pageId - 2; i <= pageId + 2; i++) {
      await this.preloadedPages.emit(i);
    }
  }

  private scrollToSignaturefield(actor: DocumentActor, zoom: boolean = false) {
    let signatureFieldIndex: number = this.signaturefields.findIndex(s => s.actorId === this.actor?.id);
    let pageWidth = 0;
    let pageHeight = 0;
    let zoomfactor = 0;
    if (signatureFieldIndex > -1) {
      let signatureFieldToRemoveAfterZooming = this.signaturefields[signatureFieldIndex];
      this.signaturefields.splice(signatureFieldIndex, 1);

      if (window.innerWidth < 991) {
        this.pageElement = <HTMLElement>document.getElementById((signatureFieldToRemoveAfterZooming.page === 1 ? 1 : signatureFieldToRemoveAfterZooming.page) + "_indicatorMobile");
      } else {
        this.pageElement = <HTMLElement>document.getElementById((signatureFieldToRemoveAfterZooming.page === 1 ? 1 : signatureFieldToRemoveAfterZooming.page - 1) + "_indicator");
      }
      pageWidth = this.pageElement.offsetWidth;
      pageHeight = this.pageElement.offsetHeight;

      this.starting_Width_SignatureFields = pageWidth * 0.20;
      this.starting_Height_SignatureFields = this.starting_Width_SignatureFields / 2.5;
      let starting_X_Position_in_perct = this.starting_Width_SignatureFields / pageWidth * 100;
      this.starting_X_Position = 50 - (starting_X_Position_in_perct / 2);

      let sigfieldX: number = 0;
      let sigfieldY: number = 0;
      if (zoom) {
        sigfieldX = signatureFieldToRemoveAfterZooming.tempx;
      } else {
        sigfieldX = ((pageWidth != null || pageWidth != undefined || signatureFieldToRemoveAfterZooming.tempx != null || signatureFieldToRemoveAfterZooming.tempx != undefined)) ? signatureFieldToRemoveAfterZooming.tempx : this.starting_X_Position;
      }
      if (zoom) {
        sigfieldY = signatureFieldToRemoveAfterZooming.tempy;
      } else {
        sigfieldY = ((pageHeight != null || pageHeight != undefined || signatureFieldToRemoveAfterZooming.tempy != null || signatureFieldToRemoveAfterZooming.tempy != undefined)) ? signatureFieldToRemoveAfterZooming.tempy : this.starting_Y_Position;
      }

      let pagePositionX: number = ((pageWidth / 100) * sigfieldX);
      let pagePositionY: number = ((pageHeight / 100) * sigfieldY);

      let y: number = 0;
      if (signatureFieldToRemoveAfterZooming.page > 1) y = pagePositionY + (this.pageElement.offsetTop + this.pageElement.offsetHeight + 10);
      else y = pagePositionY;
      if (window.innerWidth < 991)
        document.getElementById((signatureFieldToRemoveAfterZooming.page === 1 ? 1 : signatureFieldToRemoveAfterZooming.page) + "_indicatorMobile")?.scrollIntoView({ behavior: 'auto' });
      else
        document.getElementsByClassName("addSignatureFieldModal")[0].scrollTo({ top: (y - 500), behavior: 'auto' });

      setTimeout(() => {
        let bounding = this.pageElement.getBoundingClientRect();
        let top: number = (this.pageElement.offsetHeight + 10) + bounding.top;
        if (signatureFieldToRemoveAfterZooming.page === 1) top = bounding.top;
        let signaturefield: SignatureField = signatureFieldToRemoveAfterZooming;
        if (window.innerWidth < 991)
          signaturefield.y = pagePositionY - zoomfactor!;
        else {
          signaturefield.y = pagePositionY + top;
          signaturefield.x = pagePositionX
          if (signaturefield.page > 1) {
            signaturefield.y = signaturefield.y + (signaturefield.page * (zoomfactor! / 3))
          }
        }
        this.signaturefields.push(signaturefield);
      }, 500);
    }
  }

  close() {
    this.activeModal.close();
  }

  onSignatureMoving() {
    this.isDraggable = true;
    this.drawSignatureGridLines();
  }

  private drawSignatureGridLines() {
    // correct position for appearance of guiding lines
    let activePlaceholderField = document.getElementById('activePlaceholderField');

    let transform = (activePlaceholderField!.style?.transform) ? activePlaceholderField!.style.transform : "0,0";
    let transforms = transform.replace('translate(', "").replace(')', "").replace(/px/g, "").split(',');

    let container = (activePlaceholderField!.parentElement!.parentElement!.parentElement?.children[0]) as HTMLElement;
    let modal = document.getElementsByClassName("modal")[0] as HTMLElement;

    let xpos = (-(activePlaceholderField!.offsetLeft - (+transforms[0])));
    let ypos = (-(activePlaceholderField!.offsetTop - (+transforms[1])) - this.TOP_MARGIN + modal.scrollTop);
    let sigfield = activePlaceholderField!.children[2] as HTMLElement;
    let w = Number.parseInt(sigfield!.style.width.replace("px", ""));
    let h = Number.parseInt(sigfield!.style.height.replace("px", ""));

    // obtain references to the gridlines on all sides of the signaturefield
    let gridlineHorizontalBottom = document.getElementById('gridlineHorizontalBottom');
    let gridlineHorizontalTop = document.getElementById('gridlineHorizontalTop');
    let gridlineVerticalLeft = document.getElementById('gridlineVerticalLeft');
    let gridlineVerticalRight = document.getElementById('gridlineVerticalRight');

    // give the gridlines the right height and with so they are as big as the scrollable area
    gridlineVerticalLeft!.style.height = this.document.pages > 1 ? (modal.scrollHeight) - 150 + "px" : container.offsetHeight + "px"; //150px is the height of the empty space for navigation buttons when document is fully scrolled to the bottom;
    gridlineVerticalRight!.style.height = gridlineVerticalLeft!.style.height;
    gridlineHorizontalBottom!.style.width = container.offsetWidth + "px";
    gridlineHorizontalTop!.style.width = container.offsetWidth + "px";

    // make sure the gridlines align correctly to the left side and top of the scollable area
    gridlineHorizontalBottom!.style.marginLeft = (-xpos) + "px";
    gridlineHorizontalTop!.style.marginLeft = (-xpos) + "px";
    gridlineVerticalRight!.style.marginTop = (-ypos) + "px";
    gridlineVerticalLeft!.style.marginTop = (-ypos) + "px";

    // make the gridlines align correctly to the sides of the signaturefield
    let h2 = Number.parseInt(window.getComputedStyle(sigfield).marginTop.replace("px", "")) + h - 1; //-1 so the line runs exactly at the outermost pixels of the signaturefield
    gridlineHorizontalTop!.style.marginTop = window.getComputedStyle(sigfield).marginTop;
    gridlineHorizontalBottom!.style.marginTop = h2 + "px";
    gridlineVerticalRight!.style.marginLeft = (w - 1) + "px";   //-1 so the line runs exactly at the outermost pixels of the signaturefield
  }

  draggingStopped(element: HTMLElement, actorId: string, saved: boolean = false) {
    this.isDraggable = false;
    let height: number = 0;
    let width: number = 0;
    let pageId: number = 1;
    let xPercentage: number = 1;
    let yPercentage: number = 1;
    let signatureFieldIndex: number = this.signaturefields.findIndex(s => s.actorId === actorId);




    let signatureField = <HTMLElement>document.getElementById("activePlaceholderField");
    let signatureElementData = signatureField.getBoundingClientRect();
    let yPositionSignatureWithinPage = 0;
    let xPositionSignatureWithinPage = signatureElementData.left;
    // the x position is already fixed and not draggable outside of the pages

    for (let pageNr: number = 1; pageNr <= this.pageSizes.length; pageNr++) {
      this.pageElement = <HTMLElement>document.getElementById(pageNr + "_indicator");


      let pageElementData = this.pageElement.getBoundingClientRect();
      // is the top corner within this page??????
      if ((pageElementData.top < signatureElementData.top && (pageElementData.top + pageElementData.height) > signatureElementData.top) ||
        (pageElementData.top < (signatureElementData.top + signatureElementData.height) && (pageElementData.top + pageElementData.height) > (signatureElementData.top + signatureElementData.height))
      ) {
        // THIS IS THE PAGE
        // lets ALSO FORCE our box into the page.
        if (pageElementData.top > signatureElementData.top) {
          yPositionSignatureWithinPage = pageElementData.top;
          // top is above a page (between pages) but bottem was within a page so matched
        } else if (pageElementData.bottom < signatureElementData.bottom) {
          yPositionSignatureWithinPage = pageElementData.bottom - signatureElementData.height;
          // top is within page, bottem is out
        }
        else {
          yPositionSignatureWithinPage = signatureElementData.top;
        }


        xPositionSignatureWithinPage = signatureElementData.left - pageElementData.left;






        //here we calculate position
        var startingPositionX = pageElementData.left;
        var endingPositionX = pageElementData.right;
        var signaturePositionX = signatureElementData.left;

        var calculationBoxEndingPositionX = endingPositionX - startingPositionX;
        if (calculationBoxEndingPositionX == 0) {
          calculationBoxEndingPositionX = 1;
        }
        var calculationSignaturePositionX = signaturePositionX - startingPositionX;
        var positionPercentageX = calculationSignaturePositionX / calculationBoxEndingPositionX * 100;


        var signaturePositionY = yPositionSignatureWithinPage;
        var startingPositionY = pageElementData.top;
        var endingPositionY = pageElementData.bottom;
        var calculationBoxEndingPositionY = endingPositionY - startingPositionY;
        if (calculationBoxEndingPositionY == 0) {
          calculationBoxEndingPositionY = 1;
        }
        var calculationSignaturePositionY = signaturePositionY - startingPositionY;

        var positionPercentageY = calculationSignaturePositionY / calculationBoxEndingPositionY * 100;
        xPercentage = positionPercentageX;
        yPercentage = positionPercentageY;
        pageId = pageNr;
        height = signatureElementData.height;
        width = signatureElementData.width;
        break;
      }


    }

    let signfield: SignatureField = <SignatureField>{
      acronym: this.signaturefields[signatureFieldIndex].acronym,
      fullname: this.signaturefields[signatureFieldIndex].fullname,
      actorId: actorId,
      dragposition: {
        x: xPositionSignatureWithinPage,
        y: yPositionSignatureWithinPage
      },
      x: xPositionSignatureWithinPage,
      y: yPositionSignatureWithinPage,
      tempx: xPercentage,
      tempy: yPercentage,
      page: pageId,
      height: height,
      width: width,
      name: this.signaturefields[signatureFieldIndex].name,
      readonly: this.signaturefields[signatureFieldIndex].readonly,
    }
    this.signaturefields.splice(signatureFieldIndex, 1);
    this.signaturefields.push(signfield);
  }

  public saveSignatureField() {
    if (this.actor === null || this.actor === undefined) this.close();
    const element = <HTMLElement><unknown>document.getElementById("activePlaceholderField");
    this.draggingStopped(element, this.actor!.id, true);
    let signatureFieldIndex: number = this.signaturefields.findIndex(s => s.actorId === this.actor!.id);
    this.pageElement = <HTMLElement>document.getElementById(this.signaturefields[signatureFieldIndex].page + "_indicator");
    this.actor!.sigFieldPage = this.signaturefields[signatureFieldIndex].page;
    this.actor!.sigFieldH = (this.signaturefields[signatureFieldIndex].height / this.pageElement.offsetHeight * 100);
    this.actor!.sigFieldW = (this.signaturefields[signatureFieldIndex].width / this.pageElement.offsetWidth * 100);
    if (this.signaturefields[signatureFieldIndex].tempx < 0) this.signaturefields[signatureFieldIndex].tempx = 0;
    else if (this.signaturefields[signatureFieldIndex].tempx > 100) this.signaturefields[signatureFieldIndex].tempx = 100;
    if (this.signaturefields[signatureFieldIndex].tempy < 0) this.signaturefields[signatureFieldIndex].tempy = 0;
    else if (this.signaturefields[signatureFieldIndex].tempy > 100) this.signaturefields[signatureFieldIndex].tempy = 100;
    this.actor!.sigFieldX = this.signaturefields[signatureFieldIndex].tempx;
    this.actor!.sigFieldY = this.signaturefields[signatureFieldIndex].tempy;
    if (this.actor?.sigFieldX !== undefined || this.actor?.sigFieldY !== undefined) this.signatureFieldChanged.emit({ documentId: this.actor?.documentId, actor: this.actor });
    this.close();
  }

  editDocument() {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'editdocument'
    };
    this.close();
    const documentModalRef = this.modalService.open(EditDocumentModalComponent, ngbModalOptions);
    documentModalRef.componentInstance.document = this.document;
    documentModalRef.componentInstance.pageSizes = this.pageSizes;
    documentModalRef.componentInstance.request = this.request;
    documentModalRef.componentInstance.loadThumbnail.subscribe((pageId: number) => {
      this.loadThumbnail.emit(pageId);
    });
    documentModalRef.componentInstance.forceLoadThumbnail.subscribe((pageId: number) => {
      this.forceLoadThumbnail.emit(pageId);
    });
    documentModalRef.componentInstance.getRequest.subscribe(() => {
      this.getRequest.emit();
    });
  }

  signatureResized(element: any, actorId: string) {
    this.isDraggable = false;

    let signatureFieldIndex: number = this.signaturefields.findIndex(s => s.actorId === actorId);
    let el = <HTMLElement>document.getElementById(this.signaturefields[signatureFieldIndex].page + "_indicator");
    if ((el.offsetHeight + 50) < element.size.height ||
      (el.offsetWidth + 50) < element.size.width ||
      (this.signaturefields[signatureFieldIndex].x + element.size.width) > (el.offsetWidth + 50) ||
      (this.signaturefields[signatureFieldIndex].y + element.size.height) > (el.offsetHeight + 50)) {
      let height = this.signaturefields[signatureFieldIndex].height
      this.signaturefields[signatureFieldIndex].height = height;
      let signfield: SignatureField = <SignatureField>{
        acronym: this.signaturefields[signatureFieldIndex].acronym,
        fullname: this.signaturefields[signatureFieldIndex].fullname,
        actorId: this.signaturefields[signatureFieldIndex].actorId,
        dragposition: {
          x: this.signaturefields[signatureFieldIndex].dragposition.x,
          y: this.signaturefields[signatureFieldIndex].dragposition.y
        },
        x: this.signaturefields[signatureFieldIndex].x,
        y: this.signaturefields[signatureFieldIndex].y,
        tempx: this.signaturefields[signatureFieldIndex].tempx,
        tempy: this.signaturefields[signatureFieldIndex].tempy,
        page: this.signaturefields[signatureFieldIndex].page,
        height: this.signaturefields[signatureFieldIndex].height,
        width: this.signaturefields[signatureFieldIndex].width,
        name: this.signaturefields[signatureFieldIndex].name,
        readonly: this.signaturefields[signatureFieldIndex].readonly,
      };
      this.signaturefields.splice(signatureFieldIndex, 1);
      this.signaturefields.push(signfield);
      return;
    };
    this.signaturefields[signatureFieldIndex].width = element.size.width;
    this.signaturefields[signatureFieldIndex].height = element.size.height;
  }

  onSignatureResizing(element: any) {
    this.resizableSignatureWidth = element.size.width;
    this.isDraggable = true;
    this.drawSignatureGridLines();
  }

  private async onScroll() {
    if (!this.isMobile()) {
      for (let pageId: number = 1; pageId <= this.document.pages; pageId++) {
        let pageElement: HTMLElement = <HTMLElement>document.getElementById(pageId + "_indicator");
        if (this.elementInViewport(pageElement)) {
          this.pageId = pageId;
          for (let i = pageId - 3; i <= pageId + 3; i++) {
            if (i <= 0 || i > this.document.pages || this.isPagePreloaded(i)) continue;
            await this.loadPage(this._document, i);
          }
          break;
        }
      }
    }
  }

  elementInViewport(pageElement: HTMLElement) {
    let bounding = pageElement.getBoundingClientRect();
    let x = (
      bounding.bottom < 135 + 0.2 * (window.innerHeight || document.documentElement.clientHeight) ||
      bounding.top > (window.innerHeight || document.documentElement.clientHeight)
    );
    return !x;
  }

  isPagePreloaded(pageId: number) {
    return (this.document.preloadedPages.findIndex((s: PreloadedPages) => s.PageId === pageId) > -1
      && this.document.preloadedPages.find((s: PreloadedPages) => s.PageId === pageId)?.PageUrl !== null);
  }

  getPageUrl(pageId: number) {
    return this.document.preloadedPages.find((s: PreloadedPages) => s.PageId === pageId)?.PageUrl;
  }

  isMobile(): boolean {
    return window.innerWidth < 991;
  }

  private async loadPage(doc: DocumentModel, pageId: number) {
    try {
      if (pageId < 1) return;
      if (pageId <= doc.pages && doc.preloadedPages.findIndex((s: PreloadedPages) => s.PageId === pageId) === -1) {
        let preloadedPage: PreloadedPages = {
          PageId: pageId,
          PageUrl: null
        }
        doc.preloadedPages.push(preloadedPage);
        let getPage = await this.pageService.getPage(this.request.id, doc.id, pageId);
        let page = await lastValueFrom(getPage);
        let pageImage = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(page));
        let preloadPageIndex = doc.preloadedPages.findIndex(s => s.PageId === pageId);
        doc.preloadedPages[preloadPageIndex].PageUrl = pageImage;
      }
    }
    catch (error: any) {
      this.loggingService.logException(error);
    }
  }

  zoomIn() {
    let oldZoomLevel = this.currentZoomLevel;
    this.currentZoomLevel++;
    if (this.currentZoomLevel > this.zoomLevels.length - 1)
      this.currentZoomLevel = this.zoomLevels.length - 1;
    else
      this.zoomSignatureFields(oldZoomLevel, this.currentZoomLevel);
  }

  zoomOut() {
    let oldZoomLevel = this.currentZoomLevel;
    this.currentZoomLevel--;
    if (this.currentZoomLevel < 0)
      this.currentZoomLevel = 0;
    else
      this.zoomSignatureFields(oldZoomLevel, this.currentZoomLevel);
  }

  private zoomSignatureFields(oldZoomLevel: number, newZoomLevel: number) {
    this.currentPageByZooming = this.pageId;
    let oldWidth = this.zoomLevels[oldZoomLevel];
    let newWidth = this.zoomLevels[newZoomLevel];
    let zoomFactor = newWidth / oldWidth;
    let topmargin = Math.abs(Number.parseInt(document.getElementById("panzoomList")!.style.marginTop.replace("px", "")));

    for (let signatureFieldIndex = 0; signatureFieldIndex < this.signaturefields.length; signatureFieldIndex++) {

      let signatureField = this.signaturefields[signatureFieldIndex];

      if (!signatureField.readonly) signatureField.y -= topmargin;  // remove correction for top margin
      if (signatureField.readonly) signatureField.y -= ((signatureField.page - 1) * 10); // remove correction for page margins

      signatureField.width = signatureField.width * zoomFactor;
      signatureField.height = signatureField.height * zoomFactor;

      //move x y coordinates to correct position
      signatureField.x = signatureField.x * zoomFactor;

      signatureField.y = signatureField.y * zoomFactor;

      if (signatureField.readonly) signatureField.y += ((signatureField.page - 1) * 10); //reapply correction for margin between pages
      if (!signatureField.readonly) signatureField.y += topmargin;  // reapply correction for top margin
    }

    this.zoomLevelChanged.emit({ className: this.zoomClasses[newZoomLevel] });
  }

  public afterZoomLevelChanged() {
    if (this.actor) {
      this.scrollToSignaturefield(this.actor, true);
    } else {
      let e = <HTMLElement>document.getElementById(this.currentPageByZooming + "_indicator");
      e.scrollIntoView({ block: 'center', behavior: "auto", inline: "nearest" });
      this.currentPageByZooming = null;
    }
  }

  public aspectRatio(x: number, y: number) {
    return x + " / " + y;
  }

  private determineUniqueFieldName(index: number): string {
    if (this.request.actors.findIndex(p => p.fieldName == "Signature" + index && p.id != this.actor?.id) > -1)
      return this.determineUniqueFieldName(index + 1)
    return "Signature" + index;
  }

}