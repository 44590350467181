<div class="modal-body m-0 m-lg-3" style="z-index: 9999;">
    <div class="mb-4">
        <h3 ngbAutofocus translate="Request.View.WaitingFiling.WaitingForFiling"></h3>
    </div>
    <div class="row row-cols-12 default-font-size">
        <app-action-summary [actions]="actions" [documents]="documents" [requestStatus]="requestStatus"
            [displayAdditionalActionForPublishing]="true">
        </app-action-summary>
    </div>

    <p class="default-font-size" translate="Request.View.WaitingFiling.ReceivingDownloadlink"></p>

    <div class="d-table w-100 mt-4">
        <span class="float-end">
            <app-ideal-payment-button [requestId]="requestId"></app-ideal-payment-button>
        </span>
    </div>
</div>