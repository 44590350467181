import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable,ReplaySubject, Subscription } from 'rxjs';
import { User } from '../models/user';
import { WorkgroupInformationModel } from '../models/workgroup-information.model';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { OrganisationApiClient } from './organisation.apiclient';
import { LoggingService } from './logging.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public userdataAvailable:ReplaySubject<User>;
  organisationGuid = sessionStorage.getItem('organisationGuid');
  plugins: any = [];
  authCheckSub: Subscription = new Subscription;
  lastSeenStatusPageItemId: number = 0;

  constructor(private http: HttpClient, private oidcSecurityService: OidcSecurityService, private router: Router, private organisationApiClient: OrganisationApiClient, private translateService: TranslateService, private loggingService: LoggingService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser') || '{}'));
    this.userdataAvailable = new ReplaySubject<User>();
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  } 
  workgroups: Array<WorkgroupInformationModel> = [];

  public logout() {
    this.authCheckSub.unsubscribe()
    this.oidcSecurityService.logoff().subscribe(()=> {sessionStorage.clear(); });
  }
  
  public isAuthenticated: boolean = false;
  private authResponseIsValid: boolean = false;
  public authorizeCallback() {
    let returnUrl:string|null = sessionStorage.getItem("returnUrl");
	  if(returnUrl && returnUrl.indexOf('http')==0) return;	// prevent open redirect
    this.oidcSecurityService.checkAuth().subscribe(({isAuthenticated, userData}) => {
      this.isAuthenticated=isAuthenticated;
      if (isAuthenticated) { this.authenticated(userData) }
      if (this.authResponseIsValid) {
        this.authResponseValid(returnUrl) 
      } else { 
        this.authResponseInValid(returnUrl); 
        // TODO: check for returnUrl in params, set returnurl to be the same if present 
      }
    });
    this.oidcSecurityService.userData$.subscribe(data => this.getUserInfo(data));
  }
  
  public ResetUserInfo(){
    this.oidcSecurityService.checkAuth().subscribe(({isAuthenticated, userData}) => {
      if (isAuthenticated) {
        this.getUserInfo(userData);
      }
    });
  }

  private authenticated(userData: any) {
    sessionStorage.setItem("authNonce", "");
    sessionStorage.setItem("authStateControl", "");
    this.authResponseIsValid = true;
  }

  private authResponseValid(returnUrl:string|null|undefined) {
    this.authCheckSub = this.oidcSecurityService.isAuthenticated$.subscribe((authorized)=> {
      let user = JSON.parse(sessionStorage.getItem('currentUser') || '{}')
      if(!authorized.isAuthenticated) {
        if(user.isCustomer == undefined || user.isCustomer == null || user.isCustomer == false){
          this.logout();
          return;
        }
        this.authResponseInValid(document.location.pathname + document.location.search); 
      }
    })
    if (returnUrl !== null && returnUrl !== undefined && returnUrl!=="undefined")
      this.router.navigateByUrl(returnUrl)
      .catch(error => {
        this.loggingService.logException(error);
      });
    else
      this.router.navigate(['/dashboard'])
      .catch(error => {
        this.loggingService.logException(error);
      });
  }
  
  private authResponseInValid(returnUrl:string|null|undefined) {
    if (returnUrl !== null && returnUrl !== undefined && returnUrl!=="undefined")
      this.router.navigate(['/login'], { queryParams: { 'returnUrl': returnUrl } })
      .catch(error => {
        this.loggingService.logException(error);
      });
    else
      this.router.navigate(['/login'])
      .catch(error => {
        this.loggingService.logException(error);
      });
  }

  private getUserInfo(dataObject: any) {
    let userData: any = dataObject.userData;
    let u2:User=new User();
    if (userData) {
        if(userData.Workgroup != undefined){
          let workgroupData : Array<string> = new Array<string>();
          if(typeof userData.Workgroup === 'string') {
            let workgroup = JSON.stringify(userData.Workgroup);
            workgroup = JSON.parse(workgroup);
            workgroupData.push(workgroup);
          } else {
            workgroupData = userData.Workgroup;
          }
          workgroupData.forEach(d => {
            let jsonString : WorkgroupInformationModel = JSON.parse(d);
            this.workgroups.push(jsonString);
          });
        }
        u2.email = userData.email;
        u2.fullName = userData.FullName;
        u2.firstname = userData.FirstName;
        u2.prefix = userData.Prefix;
        u2.lastname = userData.LastName;
        u2.role = userData.role;
        u2.phoneNumber = userData.phone_number;
        u2.subscriptionLevel = userData.SubscriptionLevel;
        u2.organisationGuid = userData.OrganisationGuid; 
        u2.workgroup = this.workgroups;
        u2.isCustomer = userData.role && userData.role.indexOf("Customer") > -1;
        u2.isOrganisationAdmin = userData.role && userData.role.indexOf("OrganisationAdmin") > -1;
        u2.isWhitelabelAdmin = userData.role && userData.role.indexOf("EnterpriseAdmin") > -1;
        u2.isSystemAdmin = userData.role && userData.role.indexOf("WhitelabelAdmin") > -1;
        u2.sub = userData.sub;
        u2.cscProviderName = userData.CscProviderName;
        u2.signatureProvider = userData.SignatureProvider;
        u2.language = userData.language;
        this.storeUser(u2);
        this.organisationGuid = userData.OrganisationGuid;
        this.userdataAvailable.next(u2);
        if (u2.role && u2.role.indexOf('Customer')>-1) {
          this.organisationApiClient.getEnabledPlugins(u2.organisationGuid);
        }
      }
  }

  private storeUser(user: User) {
    sessionStorage.setItem("currentUser", JSON.stringify(user));
    sessionStorage.setItem("organisationGuid", user.organisationGuid);
    if(user.language != undefined && user.language != null){
      localStorage.setItem("language", user.language.toLowerCase());
      this.translateService.use(user.language.toLowerCase());
    }
    this.currentUserSubject.next(user);
  }

  urlBase64Decode(str: string): string {
    let output: string = str.replace('-', '+').replace('_', '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        throw 'Illegal base64url string!';
    }

    return window.atob(output);
  }

  validateClockSkew() : Promise<boolean>{
    return this.http.post<any>(environment.identityUrl + "/api/validateLocalTime", {"localUtcTime": new Date()}).toPromise().then(result => {
      return <boolean>result.validTime;
    });
  }

  public forceNewToken(){
    return this.oidcSecurityService.forceRefreshSession()
  }
}
