<div class="d-block col-12 mb-4">
    <!-- LOOP THROUGH ALL Actions-->
    <div *ngFor="let action of actions, index as i">
        <div class="row row-cols-12 mb-2">
            <div class="fw-bolder col-12 col-lg-6 text-truncate">
                <!-- information orb, colour coded based on Action-Request status -->
                <span class="badge perform-action-dialog-list-item rounded-pill me-2" [ngClass]="getColourClassForAction(action)">
                    <span class="align-middle text-white">{{i+1}}</span>
                </span>
                <!-- Action label, based on Request status , the checks are setup that only 1 of the 3 'can' be true  -->
                <ng-container *ngIf="displayActionAsCurrentTense(action)">
                    <span translate="{{action.action | actionToTranslationKey}}"></span>
                </ng-container>

                <ng-container *ngIf="displayActionAsPastTense(action)">
                    <span translate="{{action.action | actionToThePastTranslationKey}}"></span>
                </ng-container>

            </div>
            <div class="col-12 col-lg-6 text-truncate">
                <span>{{getMatchingDocName(action)}}</span>
            </div>
        </div>
        <!-- when awaiting 'Filing/Publishing' display an additional custom message/warning as it is currently going on -->
        <ng-container *ngIf="displayCustomFilingAction(action)">
            <div class="row row-cols-12 mb-2">
                <div class="fw-bolder col-12 col-lg-6 text-truncate">
                    <span class="badge perform-action-dialog-list-item rounded-pill me-2 bg-warning"><span class="align-middle text-white">{{i+2}}</span>
                    </span>
                    <span translate="Request.View.WaitingFiling.WaitingForConfirmation"></span>
                </div>
                <div class="col-12 col-lg-6 text-truncate">
                    <span>{{getMatchingDocName(action)}}</span>
                </div>
            </div>
        </ng-container>
    </div>
</div>