import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AppComponent } from '../../app/app.component';
import { PKIUrls } from 'src/app/constants/pkiurls.constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  public knowledgeBaseUrl = PKIUrls.KnowledgeBase;
  public createSupportTicketUrl = PKIUrls.CreateSupportTicket;
  public giveFeedbackUrl = PKIUrls.GiveFeedback;
  
  dashboardAllowed: any;
  user!: User;
  amountOfStatusPageMessages: number = 0;
  oidcAuthority: string | undefined;
  identityurl!: string;
  currentLanguage!: any;
  usersubscription: Subscription;

  constructor(private authenticationService: AuthenticationService,
  public oidcSecurityService: OidcSecurityService, public whiteLabelProvider: AppComponent) {
    this.oidcSecurityService.getConfiguration().subscribe((result) => this.oidcAuthority = result.authority);
    this.usersubscription = this.authenticationService.currentUser.subscribe((userData) => {
      this.user = userData;
      this.dashboardAllowed = this.authenticationService.currentUserValue.role && this.authenticationService.currentUserValue.role.indexOf("Customer") > -1;
    });
  }


  ngOnInit(): void {
    this.identityurl = this.oidcAuthority ? this.oidcAuthority : environment.identityUrl;
  }

  showAvatar(email:string) {
    return CryptoJS.MD5((email).toLowerCase());
  }

  logOut() {
    this.authenticationService.logout();
  }
}
