import { Component } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PerformActionRequestUnavailableComponent } from 'src/app/components/performaction/performactionrequestunavailable/performactionrequestunavailable.component';

@Component({
  templateUrl: './invite-not-found.page.html'
})
export class InviteNotFoundPage {

  constructor(private modalService: NgbModal) {
    //Constructor cannot be empty.
  }

  ngOnInit() {
    this.RequestUnavailableDialog();
  }

  RequestUnavailableDialog() {
    let ngbModalOptions: NgbModalOptions = {
      keyboard: false,
      centered: true,
      size: 'md',
      backdrop: 'static',
      fullscreen: true,
      windowClass: 'overlay-fullscreen'
    };
    this.modalService.dismissAll();
    const modalRef = this.modalService.open(PerformActionRequestUnavailableComponent, ngbModalOptions);
  }
}
