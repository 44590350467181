<ngb-toast *ngFor="let toast of toasts" [autohide]="autohide && toast.autohide" (hidden)="toastService.remove(toast)"
    [delay]="toast.delay" (mouseenter)="autohide = false" (mouseleave)="autohide = true"
    class="toast-message me-2 me-sm-2 me-md-4 me-lg-5 me-xl-5 me-xxl-5"
    [ngClass]="{'hide-close-button' : !toast.showCloseButton}">
    <!-- Title-->
    <ng-template ngbToastHeader>
        <div class="w-100 d-flex position-absolute">
            <span class="toast-header-image {{toast.classname}}"> </span>
            <strong class="toast-header-text  text-truncate">{{toast.header}}</strong>
        </div>
        <div class="pt-4 ps-4 w-100">
            <small *ngIf="toast.showDateTime" class="toast-time">{{ toast.date | timeago }}</small>
        </div>
    </ng-template>
    <!-- Body-->
    <span *ngIf="toast.showScheduleTime" class="mb-4 d-block">
        {{"Toasts.StatusPage." + toast.status | translate}}: {{getCorrectDate(toast.scheduled_for)}} - {{getCorrectDate(toast.scheduled_until)}} CMT
    </span>
    <div class="mb-2 d-block">{{toast.content}}</div>
</ngb-toast>