import { Pipe, PipeTransform } from '@angular/core';

/**
 * Parses the given Action to a Past tense TRANSLATION KEY.
 */
@Pipe({
    name: 'actionToThePastTranslationKey',
    pure: false
})
export class ActionToThePastTranslationKeyPipe implements PipeTransform {
    transform(actionAsString: string | null) {
        let mainTranslationKey = "dashboard.activitylist.actions.";
        let trimmedKey =  actionAsString?.trim();
        switch (trimmedKey) {
            case "Add":
                return mainTranslationKey + "Added";
            case "Approve":
                return mainTranslationKey + "Approved";
            case "Declare":
                return mainTranslationKey + "Declared";
            case "Decline":
                return mainTranslationKey + "Declined";
            case "Delete":
                return mainTranslationKey + "Deleted";
            case "Download":
                return mainTranslationKey + "Downloaded";
            case "Edit":
                return mainTranslationKey + "Edited";
            case "Expire":
                return mainTranslationKey + "Expired";
            case "Extend":
                return mainTranslationKey + "Extended";
            case "Filing":
                return mainTranslationKey + "Filed";
            case "Purge":
                return mainTranslationKey + "Purged";
            case "Recycle":
                return mainTranslationKey + "Recycled";
            case "Remind":
                return mainTranslationKey + "Reminded";
            case "Restore":
                return mainTranslationKey + "Restored";
            case "Send":
                return mainTranslationKey + "Sent";
            case "Sign":
                return mainTranslationKey + "Signed";
            case "Withdraw":
                return mainTranslationKey + "Withdrawn";
            case null: // ?.trim makes it undefined so we have both to be sure 
            case undefined:
            case "":
                return mainTranslationKey + "NotSet";
            default:
                return mainTranslationKey + trimmedKey;
        }
    }
}