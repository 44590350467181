<div class="modal-body m-0 m-lg-3">
    <div class="mb-4 lh-base">
        <h3>{{summaryTitle}}</h3>
        <app-greet-user></app-greet-user>
        <p class="default-font-size">
            <span ngbAutofocus translate="Request.View.Summary.PleaseReviewAndComplete"></span>
            <b> {{actions[0].deadline | date:'dd-MM-yyyy HH:mm zzzz'}}</b>
        </p>
    </div>
    <div class="row row-cols-12 row-cols-sm-12 row-cols-md-12 row-cols-lg-12 row-cols-xl-12 default-font-size">
        <app-action-summary [actions]="actions" [documents]="documents" [requestStatus]="requestStatus">
        </app-action-summary>
    </div>
    <div class="d-table w-100">
        <span class="float-start">
            <button class="btn btn-sm btn-primary" type="button" (click)="close()" translate="Button.Continue"></button>
        </span>
    </div>
</div>