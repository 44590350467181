<ng-container>
  <div class="d-block">
    <div class="modal-body xbrl-preparation-dialog-content bg-white"
      infinite-scroll [infiniteScrollDistance]="10"
      [infiniteScrollUpDistance]="10" [scrollWindow]="false" [immediateCheck]="true" [alwaysCallback]="true">
      <div cdkDropListGroup #pagelist>
        <div [innerHTML]="renderedDocument"></div>
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-center modal-footer--sticky">
      <button type="button" class="btn btn-sm btn-outline-primary btn-shadow" (click)="close()" translate="Button.Back">
      </button>
    </div>
  </div>
</ng-container>