import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-time-synchronisation-error',
  templateUrl: './time-synchronisation-error.component.html'
})
export class TimeSynchronisationErrorComponent implements OnInit {
  offset: any;
  timeZone!: string;
  currentLocalTime!: string;

  constructor() {
    // Must not be empty. It's approved by a comment.
  }

  ngOnInit(): void {
    this.currentLocalTime = formatDate(new Date(), 'HH:mm:ss dd-MM-yyyy', 'en-US');
    let offset = new Date().getTimezoneOffset();
    if(offset<0) {
        if(-offset%60<10)
        this.timeZone = "GMT+"+Math.ceil(offset/-60);
    } else {
        if(offset%60<10)
          this.timeZone = "GMT-"+Math.floor(offset/60);
    }
  }

}
