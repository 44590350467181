import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './modules/app-routing.module';
import { AppComponent } from './components/app/app.component';
import { OpenIdConfigService } from './services/openidconfig.service';
import { AuthModule, OpenIdConfiguration, StsConfigHttpLoader, StsConfigLoader, StsConfigStaticLoader } from 'angular-auth-oidc-client';
import { AuthorizedPage } from './pages/authorized/authorized.page';
import { LoggedoutPage } from './pages/logged-out/logged-out.page';
import { LoginPage } from './pages/login/login.page';
import { UnauthorizedPage } from './pages/unauthorized/unauthorized.page';
import { PageNotFoundPage } from './pages/page-not-found/page-not-found.page';
import { HeaderComponent } from './components/general/header/header.component';
import { FooterComponent } from './components/general/footer/footer.component';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { isObservable, Observable } from 'rxjs';
import { InvitePage } from './pages/invite/invite.page';
import { InviteNotFoundPage } from './pages/invite-not-found/invite-not-found.page';
import { NgxIntlTelInputModule } from '@justin-s/ngx-intl-tel-input';
import { ActivateUserPage } from './pages/activate-user/activate-user.page';
import { OnboardingCompletedPage } from './pages/onboarding-completed/onboarding-completed.page';
import { ErrorHandlerService } from './services/errorHandlerService';
import { SharedModule } from './modules/shared/shared.module';
import { TimeSynchronisationErrorComponent } from './components/general/time-synchronization-error/time-synchronisation-error.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogRSSComponent } from './components/dialog-rss/dialog-rss.component';
import { AppLayout } from './components/app/app.layout';
import { PaymentRequiredInterceptor } from './_helpers/payment-required.interceptor';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';

registerLocaleData(localeNl, 'nl');

const authFactory = (openIdConfigService: OpenIdConfigService) => {
  const config = openIdConfigService.getConfig();
  if (isObservable(config)) {
    return new StsConfigHttpLoader(<Observable<OpenIdConfiguration>>config);
  } else {
    return new StsConfigStaticLoader(<OpenIdConfiguration>config);
  }
};

@NgModule({
  declarations: [
    AppComponent,
    AppLayout,
    AuthorizedPage,
    LoggedoutPage,
    LoginPage,
    UnauthorizedPage,
    PageNotFoundPage,
    HeaderComponent,
    FooterComponent,
    InvitePage,
    InviteNotFoundPage,
    ActivateUserPage,
    OnboardingCompletedPage,
    TimeSynchronisationErrorComponent,
    DialogRSSComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: authFactory,
        deps: [OpenIdConfigService],
      }
    }),
    NgxIntlTelInputModule,
  ],
  exports: [
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PaymentRequiredInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: ErrorHandler, useClass: ErrorHandlerService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

