import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UserSettingsService {
    constructor(private httpClient: HttpClient) {

    }

    async getUserSetting(settingName: string) {
        return await this.httpClient.get(environment.apiUrl + '/usersettings/' + settingName, { responseType: 'text' });
    }

    async saveUserSettings(settings: {Key: string, Value: string}[]) {
        return await this.httpClient.post(environment.apiUrl + '/usersettings', settings);
    }

    async getRSSData(versionNr: string = "") {
        return await this.httpClient.get(environment.apiUrl + '/usersettings/GetRssData/' + versionNr);
    }
}