import { Component, Input } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DocumentActor } from 'src/app/models/actors';


export const AwaitingFilingModalOptions: NgbModalOptions = {
  keyboard: false,
  centered: true,
  size: 'lg',
  backdrop: 'static'
};

@Component({
  templateUrl: './performed-action-awaiting-filing.modal.html'
})
export class PerformedActionAwaitingFilingModal {
  @Input() actions!: DocumentActor[];
  @Input() documents!: any[];
  @Input() requestStatus!: string;
  @Input() requestId!: string;
  @Input() requestType!: string;
}
