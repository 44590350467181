import { Component } from '@angular/core';
import packageInfo from '../../../../../package.json';
import { PKIUrls } from 'src/app/constants/pkiurls.constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  public version: string = packageInfo.version;

  public ClickedOnVersion() {
    var urlToUse = PKIUrls.PatchNotes;
    window.open(urlToUse, '_blank');

  }
}
