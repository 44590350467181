import { Component, Input } from '@angular/core';
import { DocumentTypeEnum } from 'src/app/constants/document-type.enum';
import { StatusEnum } from 'src/app/constants/status.enum';
import { DocumentActor } from 'src/app/models/actors';

@Component({
  selector: 'app-action-summary',
  templateUrl: './action-summary.component.html'
})
export class ActionSummaryComponent {

  @Input() actions!: DocumentActor[];
  @Input() documents!: any[];
  @Input() requestStatus!: string;

  /**
   * Whether for an action (under certain conditions) the document type must be verified to display an additional (action) message when Request is pending
   */
  @Input() displayAdditionalActionForPublishing: boolean = false;

  /**
   * Verifies for the action what kind of Orb colour it must be, based on Action And/Or Request
   */
  public getColourClassForAction(action: DocumentActor): string {

    // you have not yet done THIS action, but the request itself is rejected
    if (!this.isActionSigned(action) && this.isRequestDeclined) {
      return "bg-danger";
    }

    // you have not yet done THIS action, also not rejected the request but the request itself is Pending
    if (!this.isActionSigned(action) && this.isRequestPending) {
      return "bg-warning";
    }

    // you have not yet done THIS action, but the request is neither Pending nor Rejected
    if (!this.isActionSigned(action)) {
      return "bg-primary";
    }

    // You did this action
    if (this.isActionSigned(action)) {
      return "bg-success";
    }

    // fallback: same as you need to do this aciton
    return "bg-primary";

  }


  /**
   *  YOU STILL NEED TO DO THE ACTION - display action as current tense
   */
  public displayActionAsCurrentTense(action: DocumentActor): boolean {
    return !this.isActionSigned(action);
  }


  /**
   *  You did the action - display action as current tense
   */
  public displayActionAsPastTense(action: DocumentActor): boolean {
    // inversed of current tense. but otherwise, you DID the action
    return !this.displayActionAsCurrentTense(action);
  }

  /**
   *  verify whether a custom message should be shown for the action below all actions (during publishing/pending)
   */
  public displayCustomFilingAction(action: DocumentActor): boolean {
    // it must be enabled
    // it must be valid for item
    // currently the request must be pending
    return this.displayAdditionalActionForPublishing && this.doesActionHaveSpecialDocumentType(action) && this.isRequestPending;
  }

  public getMatchingDocName(action: any) {
    return this.documents.find(p => p.id == action.documentId)?.name
  }

  private isActionSigned(action: DocumentActor): boolean {
    return action.signingDate != null;
  }

  private get isRequestDeclined(): boolean {
    return this.requestStatus == null || this.requestStatus === StatusEnum.Declined;
  }

  private get isRequestPending(): boolean {
    return !this.isRequestDeclined && (this.requestStatus === StatusEnum.Filing || this.requestStatus === StatusEnum.PendingDigipoort || this.requestStatus === StatusEnum.PendingSbrNexus);
  }

  // Should there be shown an additional message that filing is being processed
  private doesActionHaveSpecialDocumentType(action: DocumentActor) {

    let linkedDocument = this.documents.find(p => p.id == action.documentId);
    // if action has no document - or document type is not a filing 'Type'
    if (linkedDocument == null || (linkedDocument.documentType === DocumentTypeEnum.RegularPdf ||
      linkedDocument.documentType === DocumentTypeEnum.XbrlAuditorsReport ||
      linkedDocument.documentType === DocumentTypeEnum.XbrlAuditorsReportSFO ||
      linkedDocument.documentType === DocumentTypeEnum.XbrlAssessmentStatement ||
      linkedDocument.documentType === DocumentTypeEnum.XbrlCompositionStatement)) {
      return false;
    }


    return true;
  }
}
