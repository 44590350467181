import { formatDate } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';
import { ToastModel } from './toast.model';

@Component({
  selector: 'app-toasts',
  templateUrl: './toast.component.html'
})
export class ToastsContainer implements OnInit {
  autohide: boolean = true;
  toasts: ToastModel[] = [];
  
  constructor(public toastService: ToastService, private changeDetector: ChangeDetectorRef) {
    toastService.toastsUpdated.subscribe(() => {
      this.toasts = this.toastService.toasts.reverse();
      // Use change detector, because toasts were not always showing.
      this.changeDetector.detectChanges();
    });
  }  

  ngOnInit(): void {
    // ngOnInit must not be empty. It's approved by a comment.
  }

  getCorrectDate(date: Date) {
    let dateFormat = formatDate(date, 'MMM dd, yyyy HH:mm', 'en-US');
    return dateFormat;
  }
}