import { Injectable } from '@angular/core';
import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { IdentityBrandingService } from './identityBranding.service';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpenIdConfigService {

  constructor(private identityBrandingService: IdentityBrandingService) {
    // Must not be empty. It's approved by a comment.
  }

  getConfig(): OpenIdConfiguration | any {
    let currentdomain: string = document.location.href.split('/')[2];
    let identityurl = environment.identityUrl;
    let result: OpenIdConfiguration = {
      authority: identityurl,
      redirectUrl: window.location.origin + "/authorized",
      postLogoutRedirectUri: window.location.origin + "/loggedout",
      clientId: 'PKIsigningPlatformFrontendV2',
      scope: 'openid profile pkisigningAPI.signpdf pkisigningAPI.signxbrl pkisigning.platform pkisigning.platforminternal',
      responseType: 'code',
      useRefreshToken: false,
      silentRenew: true,
      silentRenewUrl: `${window.location.origin}/silent-renew.html`,
      renewTimeBeforeTokenExpiresInSeconds: 10,
      logLevel: LogLevel.None,
      autoUserInfo: true
    }

    if (currentdomain === "localhost:4200") { currentdomain = "whitelabeldev.pkisigning.io"; }
    else if (currentdomain === "platform.pkisigning.io") { currentdomain = "platform.pkisigning.nl"; }
    return this.identityBrandingService.getWhitelabelSettings(currentdomain).pipe(
      map((wlData: any) => {
      for (let i: number = 0; i < wlData.length; i++) {
        switch(wlData[i].name) {
          case "domain":
            identityurl = "https://" + wlData[i].value;
            break;
        }
      }
      result.authority = identityurl;
      sessionStorage.setItem("authority", ""+result.authority+"");
      return result;
    }));
  }
}
